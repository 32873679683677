import axios from "../../app/axios";
import { useState, useEffect } from "react";
import { Remove, Save } from "@mui/icons-material";

export default function EstimateGraph({ estimateMode }) {
   const [estimateArray, setEstimateArray] = useState({
      attributes: {},
      Properties: { attributes: {} },
      Chapters: { Chapter: [] },
   });
   useEffect(() => {
      parseFile(estimateMode.id);
      console.log("i fire Estimate");
   }, [estimateMode.id]);

   const parseFile = async (id) => {
      await axios.get("estimate_parse/" + id, { responseEncoding: "utf8", charset: "utf-8" }).then((res) => {
         console.log(res.data);
         setEstimateArray(res.data);
      });
   };

   const Position = ({ pos }) => {
      const isMain = pos.attributes.Code.includes("ФЕР");

      const hasRetOwnMat =
         pos.attributes.hasOwnProperty("Options") && pos.attributes.Options.includes("RetOwnMat");
      const hasRetOwnMatCSS = hasRetOwnMat ? "bg-red-50" : "";
      if (!isMain) return false;
      let dateStart = "01.08.2022";
      let dateEnd = "25.02.2023";

      return (
         <>
            <div className={hasRetOwnMatCSS + " flex flex-wrap w-[100%] p-3  rounded-md"}>
               {/*<div className="w-[5%] self-center text-center">*/}
               {/*   <input type="checkbox" />*/}
               {/*</div>*/}
               <div className="w-[40%]">
                  {pos.attributes.Caption}
                  <br />
                  <span className="italic text-gray-400 "> {pos.attributes.Code}</span>
                  <br />
                  <span className="italic text-gray-400"> {hasRetOwnMat ? "!!!Материал заказчика" : ""}</span>
               </div>
               <div className="w-[15%] pl-5">
                  {pos.Quantity.attributes.Result} ({pos.attributes.Units}) <br />
                  {pos.hasOwnProperty("PriceBase")
                     ? (pos.PriceBase.attributes.hasOwnProperty("PZ")
                          ? pos.PriceBase.attributes.PZ
                          : pos.PriceBase.attributes.Value) + " (Базисная)"
                     : ""}
                  {pos.hasOwnProperty("PriceCurr") ? pos.PriceCurr.attributes.MT + " (Текущая)" : ""}
               </div>
               <div className="w-[40%] pl-5 self-center text-right flex justify-between items-center">
                  <input className="  inline-block !w-[40%] input" value={dateStart} />
                  <Remove />
                  <input className=" inline-block input !w-[40%]" value={dateEnd} />
                  <button>
                     <Save className="text-primary" />
                  </button>
               </div>
            </div>
         </>
      );
   };
   const Header = ({ caption }) => {
      return <h3>{caption}</h3>;
   };
   const SubHeader = ({ caption }) => {
      return <h4 className="ml-5">{caption}</h4>;
   };
   const ChapterElement = ({ element }) => {
      if (Array.isArray(element)) {
         return (
            <>
               {element.map((el, i) => (
                  <div key={i}>
                     <ChapterElement element={el} />
                  </div>
               ))}
            </>
         );
      } else if (element.hasOwnProperty("Header")) {
         return (
            <>
               <Header caption={element.attributes.Caption} />
               <ChapterElement element={element.Header} />
            </>
         );
      } else if (element.hasOwnProperty("Position")) {
         return (
            <>
               <SubHeader caption={element.attributes.Caption} />
               {element.Position.map((pos, i) => (
                  <Position pos={pos} />
               ))}
            </>
         );
      }
      return <b>nothing</b>;
   };

   return (
      <>
         <div className="mx-5 p-4 shadow">
            <div className="text-xl text-center mb-5">{estimateArray.Properties.attributes.Object}</div>
            <div>
               <b>Объект</b> {estimateArray.Properties.attributes.Description} <b>ID</b> {estimateMode.id}
            </div>
            <div>
               <b>Программа</b> {estimateArray.attributes.Generator} {estimateArray.attributes.ProgramVersion}
            </div>
            <div className="mt-5">
               <div className="flex bg-primary p-3 text-white uppercase rounded-md">
                  {/*<div className="w-[5%]"></div>*/}
                  <div className="w-[40%]">Наименование</div>
                  <div className="w-[15%]">Количество</div>
                  <div className="w-[40%]">Дата (начало - конец)</div>
               </div>
               {estimateArray.Chapters.Chapter.map((chapter, i) => (
                  <div key={i}>
                     {/*{console.log(chapter)}*/}
                     <ChapterElement element={chapter} />
                  </div>
               ))}
            </div>
         </div>
      </>
   );
}
