import EstimateWrapper from "./pages/Estimate/EstimateWrapper";
import AddPostForm from "./features/posts/AddPostForm";
import Layout from "./components/Layout/Layout";
import { Routes, Route } from "react-router-dom";

function App() {
   return (
      <div className="App pt-20 ">
         <Routes>
            <Route path="/" element={<Layout />}>
               <Route index element={<EstimateWrapper />} />
               {/*<Route index element={<PostsList />} />*/}
               <Route path="post">
                  <Route index element={<AddPostForm />} />
                  {/*<Route path=":postId" element={<SinglePostPage />} />*/}
                  {/*<Route path="edit/:postId" element={<EditPostForm />} />*/}
               </Route>
            </Route>
         </Routes>
         {/*<Counter/>*/}
         {/*<PostsList/>*/}
         {/*<AddPostForm/>*/}
      </div>
   );
}

export default App;
