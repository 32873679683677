import React, { useEffect, useState } from "react";
import axios from "../../app/axios";
import ModalR from "../../components/Modal";
import {
   LocalGroceryStore,
   Equalizer,
   PlayCircleFilled,
   Visibility,
   VisibilityOff,
   Delete,
   Save,
} from "@mui/icons-material";
import UploadFile from "../../components/UploadFile/UploadFile";
import ButtonMenu from "../../components/ButtonMenu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";

export default function EstimateFiles({ setEstimateMode }) {
   const [files, setFiles] = useState([]);
   useEffect(() => {
      getFiles();
      console.log("i fire");
   }, []);

   const getFiles = () => {
      axios.get("estimate_files").then((res) => {
         console.log(res);
         if (res.data) {
            setFiles(res.data);
         }
      });
   };

   const remove = (id) => {
      axios.delete("estimate_files/" + id).then((res) => {
         console.log(res);
         getFiles();
      });
   };

   const saveFileName = (id) => {
      const data = {};
      data["name"] = document.getElementById("estimate_name" + id).value;
      patch(id, data);
   };
   const patch = (id, data) => {
      axios.patch("estimate_files/" + id, data).then((res) => {
         console.log(res);
         getFiles();
      });
   };

   const toggleVisibility = (id, flag) => {
      patch(id, { visible: flag });
   };

   const Еye = ({ visible, id, onclick }) => {
      return (
         <>
            {visible ? (
               <span onClick={() => onclick(id, 0)} className="cursor-pointer">
                  <Visibility />
                  <span>Скрыть</span>
               </span>
            ) : (
               <span onClick={() => onclick(id, 1)} className="cursor-pointer">
                  <VisibilityOff />
                  <span>Отобразить</span>
               </span>
            )}
         </>
      );
   };

   return (
      <>
         <div className="flex justify-end">
            <button className="btn-primary mx-2" href="">
               Создать
            </button>
            <ModalR title={"Импорт из XML файла ГрандСмета"} buttonTitle={"Импорт ГрандСмета"}>
               <div className="text-center">
                  <a
                     href="https://www.grandsmeta.ru/2-ob-ekty-i-smety-all/rabota-s-fajlami-smet/eksport-smet-v-razlichnye-formaty"
                     target="_blank"
                     rel="noreferrer"
                  >
                     Инструкция по экспорту в формат XML и ГрандСмета
                  </a>
               </div>
               <div className="my-10">
                  <UploadFile onFinish={() => getFiles()} />
               </div>
            </ModalR>
         </div>
         <div className="rounded-md p-2 mt-5 shadow-md bg-gray-50">
            {files.length < 1
               ? "Создайте или импортируйте смету"
               : files.map((file) => (
                    <div
                       key={file.id}
                       className="flex justify-items-start flex-nowrap items-center w-[100%] "
                    >
                       <input
                          type="text"
                          id={"estimate_name" + file.id}
                          className="input mx-4 my-2 "
                          defaultValue={file.name}
                       />
                       <ButtonMenu className="svgMarginRight" name="Действия">
                          <MenuItem onClick={() => setEstimateMode({ id: file.id, mode: "estimate" })}>
                             <PlayCircleFilled /> Отобразить смету
                          </MenuItem>
                          <MenuItem onClick={() => setEstimateMode({ id: file.id, mode: "graph" })}>
                             <Equalizer />
                             Составить график
                          </MenuItem>
                          <MenuItem onClick={() => setEstimateMode({ id: file.id, mode: "materials" })}>
                             <LocalGroceryStore />
                             Список материалов по смете
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={() => toggleVisibility()}>
                             <Еye visible={file.visible} id={file.id} onclick={toggleVisibility} />
                          </MenuItem>
                          <MenuItem onClick={() => saveFileName(file.id)}>
                             <Save />
                             Сохранить
                          </MenuItem>
                          <MenuItem onClick={() => remove(file.id)}>
                             <Delete />
                             Удалить
                          </MenuItem>
                       </ButtonMenu>
                    </div>
                 ))}
         </div>
      </>
   );
}
