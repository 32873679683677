import "./Estimate.scss";
import EstimateFiles from "./EstimateFiles";
import Estimate from "./Estimate";
import { useState } from "react";

import { Equalizer, LocalGroceryStore, MoreHoriz, PlayCircleFilled } from "@mui/icons-material";
import EstimateGraph from "./EstimateGraph";
import EstimateMaterials from "./EstimateMaterials";

export default function EstimateWrapper() {
   const [estimateMode, setEstimateMode] = useState({ mode: "estimate", id: 0 });
   const NoIDMessage = () => {
      return (
         <div className="mt-10 m-auto w-[50%] text-left">
            1) Импортируйте или создайте файл сметы
            <br />
            2) Нажмите на кнопку действий (<MoreHoriz />)
         </div>
      );
   };

   return (
      <>
         <div className="flex justify-items-start items-start">
            <div className="w-[20%]">
               <EstimateFiles setEstimateMode={setEstimateMode} />
            </div>
            <div className="w-[80%]">
               {!estimateMode.id && <NoIDMessage />}
               {estimateMode.id !== 0 && estimateMode.mode === "estimate" && (
                  <Estimate estimateMode={estimateMode} />
               )}
               {estimateMode.id !== 0 && estimateMode.mode === "graph" && (
                  <EstimateGraph estimateMode={estimateMode} />
               )}
               {estimateMode.id !== 0 && estimateMode.mode === "materials" && (
                  <EstimateMaterials estimateMode={estimateMode} />
               )}
            </div>
         </div>
      </>
   );
}
