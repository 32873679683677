import React, { useState } from "react";
import axios from "../../app/axios";
import "./UploadFile.scss";
import { closeModal } from "../../features/modal/modalSlice";
import { useDispatch } from "react-redux";

export default function UploadFile({ onFinish }) {
   const [file, setFile] = useState("");
   const dispatch = useDispatch();

   const handleChange = (e) => {
      setFile(e.target.files[0]);
   };
   const submitForm = (e) => {
      e.preventDefault();
      const data = new FormData();
      data.append("file", file);
      axios.post("estimate_files", data).then((res) => {
         //getFiles();
         console.log(res);
         onFinish();
         dispatch(closeModal());
      });
   };

   return (
      <div className="container mt-4">
         <div className="flex justify-content-center">
            <div className="mx-auto">
               <form onSubmit={submitForm} className="flex flex-nowrap justify-content-around">
                  <label className="block">
                     <span className="sr-only">Выберите файл сметы</span>
                     <input onChange={handleChange} type="file" accept="text/xml" className="input-file" />
                  </label>
                  <button className="btn-success" type="submit">
                     Начать загрузку
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
}
