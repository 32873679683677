import { Outlet } from "react-router-dom";
import Navigation from "../Navigation/Navigation";

const Layout = () => {
   return (
      <>
         <Navigation />
         <main className="App container mx-auto py-5">
            <Outlet />
         </main>
      </>
   );
};

export default Layout;
