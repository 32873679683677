import axios from "../../app/axios";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function Estimate({ estimateMode }) {
   const [estimateArray, setEstimateArray] = useState({
      attributes: {},
      Properties: { attributes: {} },
      Chapters: { Chapter: [] },
   });
   useEffect(() => {
      parseFile(estimateMode.id);
      console.log("i fire Estimate");
   }, [estimateMode.id]);

   const parseFile = async (id) => {
      await axios.get("estimate_parse/" + id, { responseEncoding: "utf8", charset: "utf-8" }).then((res) => {
         console.log(res.data);
         setEstimateArray(res.data);
      });
   };

   const Position = ({ pos }) => {
      const [isOpen, setOpenState] = useState(null);
      const [tab, setTab] = useState("1");
      const handleChange = (event, newValue) => {
         setTab(newValue);
      };
      const isMain = pos.attributes.Code.includes("ФЕР");
      const isMainCSS = isMain ? "bg-gray-100" : "text-sm opacity-60";

      const hasRetOwnMat =
         pos.attributes.hasOwnProperty("Options") && pos.attributes.Options.includes("RetOwnMat");
      const hasRetOwnMatCSS = hasRetOwnMat ? "bg-red-50" : "";
      return (
         <>
            <div className={hasRetOwnMatCSS + " " + isMainCSS + " flex flex-wrap w-[100%] p-3  rounded-md"}>
               <div className="w-[5%] self-center text-center">
                  <input type="checkbox" />
               </div>
               <div className="w-[40%]">
                  {pos.attributes.Caption}
                  <br />
                  <span className="italic text-gray-400 "> {pos.attributes.Code}</span>
                  <br />
                  <span className="italic text-gray-400"> {hasRetOwnMat ? "!!!Материал заказчика" : ""}</span>
               </div>
               <div className="w-[10%] pl-5">{pos.attributes.Units}</div>
               <div className="w-[15%] pl-5">{pos.Quantity.attributes.Result}</div>
               <div className="w-[20%] pl-5">
                  {pos.hasOwnProperty("PriceBase")
                     ? (pos.PriceBase.attributes.hasOwnProperty("PZ")
                          ? pos.PriceBase.attributes.PZ
                          : pos.PriceBase.attributes.Value) + " (Базисная)"
                     : ""}
                  {pos.hasOwnProperty("PriceCurr") ? pos.PriceCurr.attributes.MT + " (Текущая)" : ""}
               </div>
               <div className="w-[10%] pl-5 self-end text-right">
                  {isMain && (
                     <button className="text-primary text-sm" onClick={() => setOpenState(!isOpen)}>
                        {isOpen ? "Скрыть" : "Подробнее"}
                     </button>
                  )}
               </div>
               {isOpen && (
                  <div className=" text-sm w-[100%] ml-[5%] mt-2 px-4 pb-4  rounded-md bg-white">
                     <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab}>
                           <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                              <TabList
                                 onChange={handleChange}
                                 aria-label="lab API tabs example"
                                 className="flex-end"
                              >
                                 <Tab label="Материалы" value="1" className="text-white" />
                                 <Tab label="Ресурсы" value="2" />
                                 <Tab label="Состав работ" value="3" />
                              </TabList>
                           </Box>
                           <TabPanel value="1">
                              <div className="flex m-3 border-b-2 text-gray-500 border-spacing-0.5">
                                 <div className="w-[40%]">Наименование</div>
                                 <div className="w-[20%]">Мера</div>
                                 <div className="w-[20%]">Количество</div>
                                 <div className="w-[20%]">Стоимость</div>
                              </div>
                              {pos.hasOwnProperty("Resources") &&
                                 pos.Resources.Mat.map((mat) => (
                                    <div className="flex m-3">
                                       <div className="w-[40%]">
                                          {mat.attributes.Caption}
                                          <br />
                                          <span className="text-gray-500 italic">{mat.attributes.Code}</span>
                                       </div>
                                       <div className="w-[20%]">{mat.attributes.Units}</div>
                                       <div className="w-[20%]">{mat.attributes.Quantity}</div>
                                       <div className="w-[20%]">
                                          {mat.hasOwnProperty("PriceBase") &&
                                             mat.PriceBase.attributes.Value + " Базисная"}
                                          <br />
                                          {mat.hasOwnProperty("PriceCurr") &&
                                             mat.PriceCurr.attributes.Value + " Текущая"}
                                       </div>
                                    </div>
                                 ))}
                           </TabPanel>
                           <TabPanel value="2">
                              <div className="flex m-3 border-b-2 text-gray-500 border-spacing-0.5">
                                 <div className="w-[40%]">Наименование</div>
                                 <div className="w-[20%]">Мера</div>
                                 <div className="w-[20%]">Количество</div>
                                 <div className="w-[20%]">Стоимость</div>
                              </div>
                              <div className="flex m-3">
                                 <div className="w-[40%]">
                                    {pos.Resources.Tzr.attributes.Caption}
                                    <br />
                                    <span className="text-gray-500 italic">
                                       {pos.Resources.Tzr.attributes.Code}
                                    </span>
                                 </div>
                                 <div className="w-[20%]">{pos.Resources.Tzr.attributes.Units}</div>
                                 <div className="w-[20%]">{pos.Resources.Tzr.attributes.Quantity}</div>
                                 <div className="w-[20%]">
                                    {pos.Resources.Tzr.hasOwnProperty("PriceBase") &&
                                       pos.Resources.Tzr.PriceBase.attributes.Value + " Базисная"}
                                    <br />
                                    {pos.Resources.Tzr.hasOwnProperty("PriceCurr") &&
                                       pos.Resources.Tzr.PriceCurr.attributes.Value + " Текущая"}
                                 </div>
                              </div>
                              <div className="flex m-3">
                                 <div className="w-[40%]">
                                    {pos.Resources.Tzm.attributes.Caption}
                                    <br />
                                    <span className="text-gray-500 italic">
                                       {pos.Resources.Tzm.attributes.Code}
                                    </span>
                                 </div>
                                 <div className="w-[20%]">{pos.Resources.Tzm.attributes.Units}</div>
                                 <div className="w-[20%]">{pos.Resources.Tzm.attributes.Quantity}</div>
                                 <div className="w-[20%]">
                                    {pos.Resources.Tzm.hasOwnProperty("PriceBase") &&
                                       pos.Resources.Tzm.PriceBase.attributes.Value + " Базисная"}
                                    <br />
                                    {pos.Resources.Tzm.hasOwnProperty("PriceCurr") &&
                                       pos.Resources.Tzm.PriceCurr.attributes.Value + " Текущая"}
                                 </div>
                              </div>
                              {pos.Resources.hasOwnProperty("Mch") &&
                                 pos.Resources.Mch.map((mch) => (
                                    <div className="flex m-3">
                                       <div className="w-[40%]">
                                          {mch.attributes.Caption}
                                          <br />
                                          <span className="text-gray-500 italic">{mch.attributes.Code}</span>
                                       </div>
                                       <div className="w-[20%]">{mch.attributes.Units}</div>
                                       <div className="w-[20%]">{mch.attributes.Quantity}</div>
                                       <div className="w-[20%]">
                                          {mch.hasOwnProperty("PriceBase") &&
                                             mch.PriceBase.attributes.Value + " Базисная"}
                                          <br />
                                          {mch.hasOwnProperty("PriceCurr") &&
                                             mch.PriceCurr.attributes.Value + " Текущая"}
                                       </div>
                                    </div>
                                 ))}
                           </TabPanel>
                           <TabPanel value="3">
                              {pos.hasOwnProperty("WorksList") &&
                                 pos.WorksList.Work.map((work, i) => (
                                    <div key={i} className="">
                                       {work.attributes.Caption}
                                    </div>
                                 ))}
                           </TabPanel>
                        </TabContext>
                     </Box>
                  </div>
               )}
            </div>
         </>
      );
   };
   const Header = ({ caption }) => {
      return <h3>{caption}</h3>;
   };
   const SubHeader = ({ caption }) => {
      return <h4 className="ml-5">{caption}</h4>;
   };
   const ChapterElement = ({ element }) => {
      if (Array.isArray(element)) {
         return (
            <>
               {element.map((el, i) => (
                  <div key={i}>
                     <ChapterElement element={el} />
                  </div>
               ))}
            </>
         );
      } else if (element.hasOwnProperty("Header")) {
         return (
            <>
               <Header caption={element.attributes.Caption} />
               <ChapterElement element={element.Header} />
            </>
         );
      } else if (element.hasOwnProperty("Position")) {
         return (
            <>
               <SubHeader caption={element.attributes.Caption} />
               {element.Position.map((pos, i) => (
                  <Position pos={pos} />
               ))}
            </>
         );
      }
      return <b>nothing</b>;
   };

   return (
      <>
         <div className="mx-5 p-4 shadow">
            <div className="text-xl text-center mb-5">{estimateArray.Properties.attributes.Object}</div>
            <div>
               <b>Объект</b> {estimateArray.Properties.attributes.Description} <b>ID</b> {estimateMode.id}
            </div>
            <div>
               <b>Программа</b> {estimateArray.attributes.Generator} {estimateArray.attributes.ProgramVersion}
            </div>
            <div className="mt-5">
               <div className="flex bg-primary p-3 text-white uppercase rounded-md">
                  <div className="w-[5%]"></div>
                  <div className="w-[40%]">Наименование</div>
                  <div className="w-[10%]">Мера</div>
                  <div className="w-[15%]">Количество</div>
                  <div className="w-[20%]">Стоимость</div>
                  <div className="w-[10%]"></div>
               </div>
               {estimateArray.Chapters.Chapter.map((chapter, i) => (
                  <div key={i}>
                     {/*{console.log(chapter)}*/}
                     <ChapterElement element={chapter} />
                  </div>
               ))}
            </div>
         </div>
      </>
   );
}
