import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux"
// import { increaseCount, getCount } from "../../features/posts/postsSlice"
import logo from "../../assets/images/logo.svg";

const NavLink = ({ to, children }) => {
   return (
      <Link to={to} className="mx-5 ">
         {children}
      </Link>
   );
};
const Navigation = () => {
   //const dispatch = useDispatch()
   //const count = useSelector(getCount)

   return (
      <header className="bg-primary text-white p-2 flex items-center fixed w-[100%] top-0">
         <img src={logo} className="h-10 mx-5" alt="Logo" />
         <nav className="flex flex-grow w-[80%] justify-between">
            <ul className="flex ">
               <li>
                  <NavLink to="/">Смета</NavLink>
               </li>
               <li>
                  <NavLink to="/">Планирование</NavLink>
               </li>
               <li>
                  <NavLink to="/">Склад</NavLink>
               </li>
               <li>
                  <NavLink to="/">Отчеты</NavLink>
               </li>
               <li>
                  <NavLink to="/">Сотрудники</NavLink>
               </li>
            </ul>
            <ul className="flex ">
               <li>
                  <NavLink to="/">Проект</NavLink>
               </li>
               <li>
                  <NavLink to="/">Настройки</NavLink>
               </li>
               <li>
                  <NavLink to="/">Выйти</NavLink>
               </li>
            </ul>

            {/*<button*/}
            {/*    onClick={() =>*/}
            {/*        dispatch(increaseCount())*/}
            {/*    }*/}
            {/*>{count}</button>*/}
         </nav>
      </header>
   );
};

export default Navigation;
