import axios from "../../app/axios";
import { useState, useEffect } from "react";

export default function EstimateMaterials({ estimateMode }) {
   const [materialsArray, setMaterialsArray] = useState([]);
   useEffect(() => {
      getMaterials(estimateMode.id);
   }, [estimateMode.id]);

   const getMaterials = async (id) => {
      await axios
         .get("warehouse/get_materials/" + id, { responseEncoding: "utf8", charset: "utf-8" })
         .then((res) => {
            console.log(res.data);
            setMaterialsArray(res.data);
         });
   };

   const Material = ({ material }) => {
      const hasRetOwnMat = material.options.includes("RetOwnMat");
      return (
         <div className={`flex m-1 my-1 p-1  justify-between rounded-md ${hasRetOwnMat ? "bg-red-100" : ""}`}>
            <div className="w-[5%] self-center text-center">
               <input type="checkbox" />
            </div>
            <div className="w-[60%]">
               {material["caption"]} <br />
               <span className="text-gray-500 italic">
                  {material["code"]} <br />
                  {material["options"].includes("RetOwnMat") ? "Материал заказчика" : ""}
               </span>
            </div>
            <div className="w-[10%]">{material["units"]} </div>
            <div className="w-[10%]">{material["quantity"]} </div>

            <div className="w-[10%]"> 0</div>
         </div>
      );
   };

   return (
      <>
         <div className="mx-5 p-2 shadow">
            <div className="text-center">
               <h2>Склад</h2>
            </div>
            <div className="mt-5">
               <div className="flex bg-gray-200 p-2  justify-between items-center rounded-md">
                  <div className="w-[5%]"></div>
                  <div className="w-[60%]">Наименование</div>
                  <div className="w-[10%]">Мера</div>
                  <div className="w-[10%]">
                     Требуемое
                     <br />
                     Количество
                  </div>
                  <div className="w-[10%]">Наличие</div>
               </div>
               {materialsArray.map((material, i) => (
                  <div key={i}>
                     {/*{console.log(chapter)}*/}
                     <Material material={material} />
                  </div>
               ))}
            </div>
         </div>
      </>
   );
}
