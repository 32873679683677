import { closeModal, openModal } from "../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import "./Modal/Modal.css";

const Modal = ({ title, buttonTitle, onClose, children }) => {
    const dispatch = useDispatch();
    const { isOpen } = useSelector((store) => store.modal);

    return (
        <>
            <button className=" ml-2 btn-primary" onClick={() => dispatch(openModal())}>
                {buttonTitle}
            </button>
            {isOpen && (
                <div className="modal">
                    <div className="modal-body">
                        <div className="text-center text-xl mb-7">{title}</div>
                        {children}
                        <div className="text-right">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    dispatch(closeModal());
                                }}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default Modal;
